.App {
	text-align: center;
}

.invisible {
	display: none;
}

.footer {
	font: .7rem "Neue Helvetica W05", Helvetica, Arial, sans-serif;
	margin: 2rem;
}

#logoWrapper {
	margin: 2rem auto 1rem;
	width: 15rem;
	text-align: center;
	overflow: visible;
}

#logo {
	margin-bottom: 0;
	text-align: left;
	line-height: 2rem;
	font-size: 2.5rem;
	font-weight: bold;
	white-space: nowrap;
}

#tagline {
	text-align: left;
	font-size: 1rem;
	font-weight: normal;
	white-space: nowrap;
}

.App h1 {
	margin-bottom: 0;
	font-weight: normal;
}

#appWrapper {
	text-align: center;
	margin-bottom: 4rem;
}

.instrument-button {
	margin: 1rem;
	padding: 0;
	width: 3rem;
	height: 3rem;
	border: 0;
	opacity: 1;
}

#keyboard-button {
	background-image: url("img/keyboard-button.svg");
}

#guitar-button {
	background-image: url("img/guitar-button.svg");
}

#bass-button {
	background-image: url("img/bass-button.svg");
}

#keyboardContainer, #fretboardContainer, #bassFretboardContainer {
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
}

#svgStandardGuitar, #svgBassGuitar {
	margin-left: 50px;
}

#fret1_1, 
#fret2_1, 
#fret3_1, 
#fret4_1, 
#fret5_1 {
	stroke: #999999;
}

#caption {
	margin-bottom: 2rem;
	font-size: 2rem;
}

#lengthSelector {
	text-align: center;
}

#lengthSelector #fblLabel {
	margin-left: 1rem;
	margin-bottom: 2rem;
}

#tonicControlsWrapper {
	vertical-align: middle;
}

.tonicControls {
	display: inline-block;
	padding: 0rem;
	width: 3rem;
	height: 2rem;
	vertical-align: middle;
	color: #ffffff;
	font-weight: normal;
	font-size: 1.2rem;
	line-height: 1.2rem;
	background-color: #1a73e8;
	border: 1px solid #1a73e8;
	border-radius: 12px;
	cursor: pointer;
	filter: drop-shadow(.15rem .15rem 0.1rem #666666);
}
.tonicControls:active {
	filter: none;
}

#tonicDisplay {
	display: inline-block;
	width: 7rem;
	height: 3rem;
	vertical-align: middle;
	font-size: 1.5rem;
	line-height: 3rem;
	font-weight: bold;
}

#tonicSelector {
	width: 6rem;
	height: 2.5rem;
	font-size: 1.5rem;
	color: #1a73e8;
}

#labelTypeLabel123, #labelTypeLabelABC {
	margin: 1rem;
	vertical-align: middle;
}

#labelTypeRange {
	width: 2rem;
	vertical-align: middle;
}


#tonicSelectorSection, #scaleTypeSelectorSection, #densitySelectorSection, #labelSelectorSection {
	margin: 1rem 0;
}

@media (max-width: 920px) {
  #keyboardContainer, #fretboardContainer, #bassFretboardContainer {
		font-size: 0.8rem;
	}
}

